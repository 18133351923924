import API from "@/apis/AccountingFirm/SuperAdminAccountingFirmProductApi"
import MissingModuleParametersException from '@/exceptions/Product/MissingModuleParametersException'
import { RequiredArguments } from "@/helpers/methods"
import DefaultInterceptor from '@/plugins/axios/DefaultInterceptor'

const Private = {
  MISSING_INFORMATION: 'accounting-firm-product.missing-module-parameters',
  requests: {
    accountingFirmProducts: {
      read: API.sa.accountingFirmProduct.read,
      replace: API.sa.accountingFirmProduct.replace,
    }
  },
  services: {
    accountingFirmProducts: {
      /**
       * Fetches products for a given accounting firm.
       * @param {number} accountingFirmId - The ID of the accounting firm.
       * @returns {Promise<Object>} A promise that resolves with the response of the GET request.
       */
      read: async function (accountingFirmId) {
        RequiredArguments({ accountingFirmId });
        return await Private.requests.accountingFirmProducts.read(accountingFirmId);
      },
      replace: async function (accountingFirmId, data) {
        RequiredArguments({ accountingFirmId, data });
        try {
          return await Private.requests.accountingFirmProducts.replace(accountingFirmId, { show_error: false }, data);
        } catch (error) {
          const errorCode = error?.response?.data?.error?.code
          const errorMessage = error?.response?.data?.error?.message
          const status = error?.response?.status
          if (status === 409 && errorCode === Private.MISSING_INFORMATION) {
            const product = error.response.data.error.product
            const module = error.response.data.error.module
            throw new MissingModuleParametersException(errorMessage, product, module)
          }
          if (error?.response?.config) {
            error.response.config.show_error = true
          }
          return DefaultInterceptor.onResponseError(error)
        }
      },
    }
  }
}

export default {
  replaceProducts: Private.services.accountingFirmProducts.replace,
  getProducts: Private.services.accountingFirmProducts.read,
}
