<template>
	<v-dialog fullscreen persistent :value="dialog">
		<v-card>
			<v-toolbar dark color="primary">
				<v-toolbar-title v-text="dialogTitle" />
				<v-spacer></v-spacer>
				<v-btn icon @click="closeDialog()">
					<v-icon>close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-layout column fill-height style="position: relative">
				<div v-show="loading" class="loader-container">
					<v-progress-circular color="primary" indeterminate size="50" width="6" />
				</div>
				<v-form v-model="isFormValid">
					<w-section :title="$t('sa.applications.labels.general_informations')">
						<v-layout row wrap>
							<v-flex xs6 pr-1>
								<w-text-input v-model="accountingFirm.name" data-qa="accounting_firm_name" :disabled="!canUpdate" :label="$t('accounting_firms.name')" maxlength="191" required />
							</v-flex>
							<v-flex xs3 px-1>
								<w-text-input v-model="accountingFirm.hubspot_id" :disabled="!canUpdate" :label="$t('sa.accounting-firms.hubspot_id')" regex="^[0-9]+$" />
							</v-flex>
							<v-flex xs3 pl-1>
								<w-text-input v-model="accountingFirm.stripe_id" :disabled="!canUpdate" :label="$t('sa.accounting-firms.stripe_id')" />
							</v-flex>
							<v-flex pr-1 xs6>
								<v-autocomplete
									v-model="accountingFirm.holding_id"
									:disabled="!canUpdate || !!value"
									:items="holdings"
									item-text="name"
									item-value="id"
									:label="$t('sa.accounting-firms.holding') + '*'"
									:loading="holdingLoading"
									:rules="[v => !!v || $t('required')]"
									required
								/>
							</v-flex>
							<v-flex px-1 xs6>
								<w-text-input
									v-model="accountingFirm.domain"
									:disabled="!canUpdate"
									:label="$t('accounting_firms.domain')"
									maxlength="191"
									:rules="[rules.domain]"
									required
								/>
							</v-flex>
							<v-flex xs12>
								<v-textarea v-model="accountingFirm.welcome_message" color="primary" :disabled="!canUpdate" :label="$t('sa.home_message')" />
							</v-flex>
						</v-layout>
					</w-section>
					<w-section v-if="accountingFirm && !accountingFirm.deleted_at" :title="$t('sa.whitelabel')">
						<WhiteLabelForm v-model="whiteLabels" :loading="whiteLabelsLoading" :readonly="!canUpdate" :validation.sync="isWhiteLabelValid" />
					</w-section>
					<w-section v-if="value" :title="$t('sa.apps')">
						<v-layout row wrap>
							<v-flex :class="$vuetify.breakpoint.mdAndUp ? 'pr-1' : ''" xs12 md6 px-1>
								<v-layout row wrap>
									<w-text-input v-model="accountingFirm.android_app" :disabled="!canUpdate" :label="$t('sa.android_app')" maxlength="191" type="url" />
									<w-btn :disabled="!accountingFirm.android_app" :href="accountingFirm.android_app" target="_blank">
										{{ $t('sa.check') }}
									</w-btn>
								</v-layout>
							</v-flex>

							<v-flex :class="$vuetify.breakpoint.mdAndUp ? 'pr-1' : ''" xs12 md6 px-1>
								<v-layout row wrap>
									<w-text-input v-model="accountingFirm.ios_app" :disabled="!canUpdate" :label="$t('sa.ios_app')" maxlength="191" type="url" />
									<w-btn :disabled="!accountingFirm.ios_app" :href="accountingFirm.ios_app" target="_blank">
										{{ $t('sa.check') }}
									</w-btn>
								</v-layout>
							</v-flex>
						</v-layout>
					</w-section>
					<w-section :title="$t('sa.accounting-firms.products')">
						<InvoicedProducts
							v-model="products"
							:accounting-firm-id="Number(value)"
							required
							@initialProductsLoaded="initialProducts = $event"
						/>
						<MissingParametersDialog ref="moduleDialog" v-model="missingInformationsDialog" :module="moduleMissingInformations" />
					</w-section>
					<w-section v-if="!value" :title="$t('sa.accounting-firms.user')">
						<v-layout row wrap>
							<v-flex xs12>
								<w-text-input v-model="accountingFirm.email" :label="$t('accounting_firms.leader.mail')" type="email" required />
							</v-flex>
							<v-flex xs6 pr-1>
								<w-text-input
									v-model="accountingFirm.lastname"
									:disabled="!canEditUsername"
									:label="$t('accounting_firms.leader.last_name')"
									type="text"
									:required="!userAlreadyExist"
								></w-text-input>
							</v-flex>
							<v-flex xs6 pl-1>
								<w-text-input
									v-model="accountingFirm.firstname"
									:disabled="!canEditUsername"
									:label="$t('accounting_firms.leader.first_name')"
									type="text"
									:required="!userAlreadyExist"
								></w-text-input>
							</v-flex>
							<!-- checkbox:is_group_admin -->
							<v-flex xs12>
								<w-switch v-model="accountingFirm.is_group_admin" :label="$t('accounting_firms.leader.is_group_admin')" />
							</v-flex>
						</v-layout>
					</w-section>
					<w-section :title="$t('sa.accounting-firms.ecm_settings')">
						<v-layout column>
							<AccountingFirmCatalogTreeStructures
								v-model="accountingFirm.catalog_tree_structures"
								:disabled="!canUpdate"
								:items="catalogTreeStructures"
								:label="$t('sa.ged_structures_available')"
								:loading="catalogTreeStructuresLoading"
								multiple
								required
								return-object
							/>
							<w-select
								v-model="accountingFirm.default_catalogTreeStructures"
								:disabled="!canUpdate"
								:items="accountingFirm.catalog_tree_structures"
								item-text="name"
								item-value="id"
								:label="$t('sa.ged_structures_default')"
								:loading="catalogTreeStructuresLoading"
								required
								return-object
							/>
							<AccountingFirmCatalogTreeStructures 
								v-model="accountingFirm.catalog_tree_structure_id"
								:disabled="!canUpdate"
								:items="catalogTreeStructures"
								:loading="catalogTreeStructuresLoading"
								:label="$t('sa.ged_structures_accounting_firm')"
								required
							/>
						</v-layout>
					</w-section>
				</v-form>
				<v-layout row>
					<v-spacer />
					<w-btn v-if="accountingFirm && accountingFirm.id && !accountingFirm.deleted_at" color="error" @click="openDeleteDialog(false)">{{
						$t('actions.deactivate')
					}}</w-btn>
					<w-btn v-if="accountingFirm && accountingFirm.id && accountingFirm.deleted_at" color="error" @click="openDeleteDialog(true)">{{
						$t('actions.definitly_delete')
					}}</w-btn>
					<w-btn v-if="accountingFirm && accountingFirm.id && accountingFirm.deleted_at" @click="dearchive()">{{ $t('actions.restore') }}</w-btn>
					<w-btn-save v-if="!accountingFirm || !accountingFirm.deleted_at" :disabled="!canSave" :loading="saveLoading" @click="saveAccountingFirm" />
				</v-layout>
			</v-layout>
		</v-card>
	</v-dialog>
</template>

<script>
import Vue from 'vue'

import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
import Validator from '@/mixins/Validator'
import SuperAdminAccountingFirmProductService from "@/services/SuperAdmin/SuperAdminAccountingFirmProductService";

const NO = 'actions.no'
const YES = 'actions.yes'

export default {
	name: 'AccountingFirmForm',
	components: {
		AccountingFirmCatalogTreeStructures: () => ({
			component: import('@/components/SuperAdmin/AccountingFirms/AccountingFirmCatalogTreeStructures')
		}),
		InvoicedProducts: () => ({
			component: import('@/components/Group/Manager/Products/InvoicedProducts')
		}),
		MissingParametersDialog: () => ({
			component: import('@/components/SuperAdmin/AccountingFirms/MissingParametersDialog')
		}),
		WhiteLabelForm: () => ({
			component: import('@/components/WhiteLabel/WhiteLabelForm')
		})
	},
	mixins: [SuperAdminModuleGuard, Validator],
	props: {
		// eslint-disable-next-line vue/require-prop-types
		value: {
			required: true
		}
	},
	data: function () {
		return {
			accountingFirm: {
				catalog_tree_structures: [],
				catalog_tree_structure_id: null
			},
			canEditUsername: false,
			catalogTreeStructures: [],
			catalogTreeStructuresLoading: false,
			dialog: false,
			holdings: [],
			holdingLoading: false,
			initialProducts: null,
			isFormValid: false,
			isWhiteLabelValid: false,
			loading: false,
			missingInformationsDialog: false,
			modulesForAccountingFirms: [],
			modulesForVendors: [],
			moduleMissingInformations: null,
			originalAccountingFirm: {
				catalog_tree_structures: []
			},
			products: [],
			saveLoading: false,
			userAlreadyExist: false,
			whiteLabels: [
				{
					primary_color: '#2DC092',
					secondary_color: '#21375C',
					theme: 'light'
				},
				{
					primary_color: '#2DC092',
					secondary_color: '#21375C',
					theme: 'dark'
				}
			],
			whiteLabelsCopy: [
				{
					primary_color: '#2DC092',
					secondary_color: '#21375C',
					theme: 'light'
				},
				{
					primary_color: '#2DC092',
					secondary_color: '#21375C',
					theme: 'dark'
				}
			],
			whiteLabelsLoading: false
		}
	},
	computed: {
		accountingFirmData: function () {
			if (!this.value) {
				return this.accountingFirm
			}

			const result = {}
			for (const [key, value] of Object.entries(this.accountingFirm)) {
				if (value != this.originalAccountingFirm[key]) {
					result[key] = value
				}
			}

			return result
		},
		canSave: function () {
			if (this.products.length === 0) {
				return false
			}

			return (
				(!this.accountingFirm?.id && this.isFormValid && this.isWhiteLabelValid) ||
				(this.accountingFirm?.id && this.isFormValid && (this.isDirty || this.productsChanged || this.isDarkModeEdited || this.isLightModeEdited))
			)
		},
		canUpdate: function () {
			return this.accountingFirm?.deleted_at == null
		},
		dialogTitle: function () {
			let result = null

			if (this.accountingFirm?.id) {
				result = this.$t('sa.accounting-firms.modify_accounting_firm', { name: this.accountingFirm.name })
			} else {
				result = this.$t('sa.accounting-firms.create_accounting_firm')
			}

			return result
		},
		isDarkModeEdited: function () {
			const darkMode = this.whiteLabels.find(({ theme }) => theme === 'dark')
			const darkModeCopy = this.whiteLabelsCopy.find(({ theme }) => theme === 'dark')
			if (darkMode === undefined) {
				return false
			}
			const properties = ['logo', 'primary_color', 'secondary_color']
			return properties.some(property => {
				return darkMode[property] !== darkModeCopy[property]
			})
		},
		isDirty: function () {
			return Object.values(this.accountingFirmData).length > 0
		},
		isLightModeEdited: function () {
			const lightMode = this.whiteLabels.find(({ theme }) => theme === 'light')
			const lightModeCopy = this.whiteLabelsCopy.find(({ theme }) => theme === 'light')
			if (lightMode === undefined) {
				return false
			}
			const properties = ['logo', 'primary_color', 'secondary_color']
			return properties.some(property => {
				return lightMode[property] !== lightModeCopy[property]
			})
		},
		productsChanged: function () {
			return JSON.stringify(this.products) !== JSON.stringify(this.initialProducts)
		},
	},
	watch: {
		'accountingFirm.email': {
			handler: function (val) {
				if (this.isEmailValid(val)) {
					this.getUserInfoByEmail(val)
				} else {
					if (this.userAlreadyExist) {
						Vue.set(this.accountingFirm, 'firstname', '')
						Vue.set(this.accountingFirm, 'lastname', '')
					}
					this.canEditName = false
					this.userAlreadyExist = false
				}
			}
		},
		value: {
			handler: 'loadAccountingFirm'
		}
	},
	mounted: function () {
		this.dialog = true
		this.loadAccountingFirm()
		this.loadCatalogTreeStructures()
		this.loadingHoldings()
		if (!this.value) {
			this.loadModules()
		}
	},
	methods: {
		closeDialog: function () {
			this.dialog = false
			this.appService.goTo('sa-accounting-firms-list')
		},
		createAccountingFirm: function () {
			this.loading = true
			const data = {
				catalog_tree_structure_id: this.accountingFirm.catalog_tree_structure_id,
				customer_analytic_uri: this.accountingFirm.customer_analytic_uri,
				domain: this.accountingFirm.domain,
				email: this.accountingFirm.email,
				firstname: this.accountingFirm.firstname,
				holding_id: this.accountingFirm.holding_id,
				hubspot_id: this.accountingFirm.hubspot_id,
				is_group_admin: this.accountingFirm.is_group_admin,
				lastname: this.accountingFirm.lastname,
				name: this.accountingFirm.name,
				stripe_id: this.accountingFirm.stripe_id,
				welcome_message: this.accountingFirm.welcome_message
			}
			if (
				this.accountingFirm.catalog_tree_structures &&
				this.accountingFirm.catalog_tree_structures.length > 0 &&
				this.accountingFirm.default_catalogTreeStructures &&
				this.accountingFirm.default_catalogTreeStructures.id
			) {
				this.accountingFirm.catalog_tree_structures.map(cts => {
					cts.is_default = cts.id == this.accountingFirm.default_catalogTreeStructures.id
				})
				data.catalog_tree_structures = JSON.stringify(this.accountingFirm.catalog_tree_structures)
			}
			return this.service
				.createAccountingFirm(data)
				.catch(error => {
					if (error?.name != 'DomainAlreadyExistsException') {
						throw error
					}

					return this.$dialog
						.warning({
							actions: {
								false: this.$t(NO),
								true: this.$t(YES)
							},
							text: this.$t('sa.accounting_firms_manager.errors.domain_already_exists_create_question'),
							title: this.$t('sa.accounting_firms_manager.errors.domain_already_exists')
						})
						.then(res => {
							if (res) {
								data.force = true
								return this.service.createAccountingFirm(data)
							} else {
								throw error
							}
						})
				})
				.then(accountingFirm => {
					return Promise.allSettled([
						this.replaceProducts(accountingFirm.id, {product_id: this.products}),
						this.getWhiteLabels(accountingFirm.id)
							.then(whiteLabels => {
								if (whiteLabels.length > 0) {
									return this.updateWhiteLabels()
								} else {
									return this.createAccountingFirmWhiteLabel(accountingFirm)
								}
							})
							.then(() => {
								return accountingFirm
							})
					])
				})
				.finally(() => {
					this.loading = false
				})
		},
		createAccountingFirmWhiteLabel: function (accountingFirm) {
			return this.createWhiteLabel(accountingFirm.id)
				.catch(err => {
					this.service.deleteAccountingFirm(accountingFirm.id)
					throw err
				})
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.accounting_firm_created'))
				})
		},
		createWhiteLabel: function (accountingFirmId) {
			return this.service.createWhiteLabel(accountingFirmId, this.whiteLabels)
		},
		dearchive: function () {
			this.service.dearchiveAccountingFirm(this.accountingFirm.id).then(() => {
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.accounting_firm_restored'))
				this.closeDialog()
			})
		},
		findWhiteLabelData: function (accountingFirmId) {
			this.whiteLabelsLoading = true
			return new Promise(resolve => {
				if (!accountingFirmId) {
					return resolve(null)
				}
				const promise = this.service.listWhiteLabels(accountingFirmId)
				resolve(promise)
			})
				.then(whiteLabelData => {
					if (whiteLabelData) {
						this.whiteLabels.splice(0, this.whiteLabels.length)
						this.whiteLabelsCopy.splice(0, this.whiteLabelsCopy.length)
						whiteLabelData.forEach(whiteLabel => {
							this.whiteLabels.push(whiteLabel)
							this.whiteLabelsCopy.push({ ...whiteLabel })
						})
					}
				})
				.finally(() => {
					this.whiteLabelsLoading = false
				})
		},
		getUserInfoByEmail: function (email) {
			this.service
				.getUserByEmail(email, { show_error: false })
				.then(userInfo => {
					if (userInfo) {
						this.userAlreadyExist = true
						this.canEditUsername = false
						Vue.set(this.accountingFirm, 'firstname', userInfo.firstname)
						Vue.set(this.accountingFirm, 'lastname', userInfo.lastname)
					} else {
						if (this.userAlreadyExist) {
							Vue.set(this.accountingFirm, 'firstname', '')
							Vue.set(this.accountingFirm, 'lastname', '')
						}
						this.userAlreadyExist = false
						this.canEditUsername = true
					}
				})
		},
		getWhiteLabels: function (accountingFirmId) {
			return this.service.listWhiteLabels(accountingFirmId)
		},
		informAccountingFirmSuccessfullyDeleted: function () {
			this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.accounting_firm_deleted'))
		},
		isEmailValid: function (email) {
			return email
				.trim()
				.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
		},
		loadAccountingFirm: function () {
			this.loading = true

			let promise = Promise.resolve()
			if (!this.value) {
				this.accountingFirm = {
					catalog_tree_structures: []
				}
			} else {
				promise = this.service.getAccountingFirm(this.value).then(accountingFirm => {
					this.accountingFirm = accountingFirm
					this.accountingFirm.catalog_tree_structures.map(structure => {
						if (structure.pivot.is_default) {
							this.accountingFirm.default_catalogTreeStructures = structure
						}
					})
					this.originalAccountingFirm = { ...accountingFirm }

					if (!this.accountingFirm.deleted_at) {
						return this.findWhiteLabelData(this.accountingFirm.id)
					}
				})
			}

			return promise.finally(() => {
				this.loading = false
			})
		},
		loadCatalogTreeStructures: function () {
			this.catalogTreeStructuresLoading = true
			this.service
				.getCatalogTreeStructures()
				.then(catalogTreeStructures => {
					this.catalogTreeStructures = catalogTreeStructures
				})
				.finally(() => {
					this.catalogTreeStructuresLoading = false
				})
		},
		loadingHoldings: function () {
			this.holdingLoading = true
			this.service
				.listHoldings()
				.then(holdings => {
					holdings.forEach(holding => {
						holding.name += ` (ID: ${holding.id})`

						this.holdings.push(holding)
					})
				})
				.finally(() => {
					this.holdingLoading = false
				})
		},
		loadModules: function () {
			return this.service
				.getAccountingFirmModules()
				.then(({ modules_for_accounting_firms: modulesForAccountingFirms, modules_for_companies: modulesForVendors }) => {
					this.modulesForAccountingFirms = modulesForAccountingFirms
					this.modulesForVendors = modulesForVendors
				})
		},
		openDeleteDialog: function (definitly = false) {
			let text = ''
			let title = ''
			if (definitly) {
				text = this.$t('sa.accounting_firms_manager.actions.are_you_sure_definitly', { name: this.accountingFirm.name })
				title = this.$t('sa.accounting_firms_manager.actions.definitly_delete')
			} else {
				text = this.$t('sa.accounting_firms_manager.actions.are_you_sure', { name: this.accountingFirm.name })
				title = this.$t('sa.accounting_firms_manager.actions.delete')
			}
			this.$dialog
				.warning({
					text: text,
					title: title,
					actions: {
						false: this.$t(NO),
						true: this.$t(YES)
					}
				})
				.then(res => {
					if (res) {
						if (definitly) {
							return this.openDialogDeleteVendors()
						} else {
							return this.service.deleteAccountingFirm(this.accountingFirm.id).then(() => {
								let isSoftDeleted = this.accountingFirm.deleted_at
								this.accountingFirm.deleted_at = true
								this.accountingFirms = this.accountingFirms.filter(item => {
									return item.id !== this.accountingFirm.id
								})
								if (isSoftDeleted) {
									this.informAccountingFirmSuccessfullyDeleted()
								} else {
									this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.accounting_firm_archived'))
								}
							})
						}
					}
				})
				.then(() => {
					this.closeDialog()
				})
		},
		openDialogDeleteVendors: function () {
			let text = this.$t('sa.accounting_firms_manager.actions.ask_definitly_delete_vendor')
			let title = this.$t('sa.accounting_firms_manager.actions.definitly_delete_vendor')
			return this.$dialog
				.warning({
					text: text,
					title: title,
					actions: {
						false: this.$t(NO),
						true: this.$t(YES)
					}
				})
				.then(res => {
					if (res) {
						return this.openDialogConfirmDeleteVendors()
					} else {
						return this.service.deleteAccountingFirm(this.accountingFirm.id).then(() => {
							this.informAccountingFirmSuccessfullyDeleted()
						})
					}
				})
		},
		openDialogConfirmDeleteVendors: function () {
			let text = this.$t('sa.accounting_firms_manager.actions.ask_definitly_delete_vendor_confirm')
			let title = this.$t('sa.accounting_firms_manager.actions.definitly_delete_vendor_confirm')
			return this.$dialog
				.warning({
					text: text,
					title: title,
					actions: {
						false: this.$t(NO),
						true: this.$t(YES)
					}
				})
				.then(res => {
					let params = {}
					if (res) {
						params.deleteVendors = true
					}
					return this.service.deleteAccountingFirm(this.accountingFirm.id, params).then(() => {
						this.informAccountingFirmSuccessfullyDeleted()
					})
				})
		},
		getMissingInformations: function (moduleMissingInformations) {
			this.moduleMissingInformations = moduleMissingInformations
			this.missingInformationsDialog = true
			
			return new Promise((resolve, reject) => {
				this.$refs.moduleDialog.$once('close', function () {
					this.missingInformationsDialog = false
					this.moduleMissingInformations = null
					this.$refs.moduleDialog.$off('save')
					reject('Cancelled')
				}.bind(this))

				this.$refs.moduleDialog.$once('save', function (eventValue) {
					this.missingInformationsDialog = false
					this.moduleMissingInformations = null
					this.$refs.moduleDialog.$off('close')
					resolve(eventValue)
				}.bind(this))

			})
		},
		removeProduct: function (productToRemove) {
			const productIndex = this.products.findIndex(productId => productId == productToRemove.id)
			if (productIndex > -1) {
				this.products.splice(productIndex, 1)
			}
		},
		replaceProducts: async function (accountingFirmId, data) {
			if (!this.productsChanged) {
				return 
			}
			
			try {
				return await SuperAdminAccountingFirmProductService.replaceProducts(accountingFirmId, data)
			} catch (error) {
				if (error?.name != 'MissingModuleParametersException') {
					throw error
				}

				const moduleMissingInformations = error.module
				try {
					const missingInformations = await this.getMissingInformations(moduleMissingInformations)
					if (!data.modules) {
						data.modules = []
					}
					data.modules.push({ id: moduleMissingInformations.id, parameters: missingInformations})

					return await this.replaceProducts(accountingFirmId, data)
				} catch (missingInformationsError) {
					if (missingInformationsError != 'Cancelled') {
						throw missingInformationsError
					}
					this.removeProduct(error.product)
					
					return await this.replaceProducts(this.accountingFirm.id, {product_id: this.products})
				}
			}
		},
		saveAccountingFirm: function () {
			this.saveLoading = true

			let promise

			promise = this.service.checkDomain(this.accountingFirm.domain).catch(error => { throw error })

			if (this.value) {
				promise = promise.then(this.updateAccountingFirm)
			} else {
				promise = promise.then(this.createAccountingFirm)
			}

			promise
				.then(() => {
					this.closeDialog()
				})
				.finally(() => {
					this.saveLoading = false
				})
		},
		updateAccountingFirm: function () {
			const data = this.accountingFirmData
			if (
				this.accountingFirm.catalog_tree_structures &&
				this.accountingFirm.catalog_tree_structures.length > 0 &&
				this.accountingFirm.default_catalogTreeStructures &&
				this.accountingFirm.default_catalogTreeStructures.id
			) {
				this.accountingFirm.catalog_tree_structures.map(cts => {
					cts.is_default = cts.id == this.accountingFirm.default_catalogTreeStructures.id
				})
				data.catalog_tree_structures = this.accountingFirm.catalog_tree_structures
			}
			return this.service
				.updateAccountingFirm(this.accountingFirm.id, data)
				.catch(error => {
					if (error?.name === 'DomainAlreadyExistsException') {
						return this.$dialog
							.warning({
								text: this.$t('sa.accounting_firms_manager.errors.domain_already_exists_update_question'),
								title: this.$t('sa.accounting_firms_manager.errors.domain_already_exists'),
								actions: {
									false: this.$t(NO),
									true: this.$t(YES)
								}
							})
							.then(res => {
								if (res) {
									data.force = true
									return this.service.updateAccountingFirm(this.accountingFirm.id, data)
								} else {
									throw error
								}
							})
					} else {
						throw error
					}
				})
				.then(() => {
					return Promise.allSettled([
						this.replaceProducts(this.accountingFirm.id, {product_id: this.products}),
						this.updateWhiteLabels()
					])
				})
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.accounting_firm_updated'))
				})
		},
		updateWhiteLabels: function () {
			const promises = []
			if (this.isDarkModeEdited) {
				promises.push(this.updateWhiteLabel('dark'))
			}
			if (this.isLightModeEdited) {
				promises.push(this.updateWhiteLabel('light'))
			}
			return Promise.allSettled(promises)
		},
		updateWhiteLabel: function (theme) {
			return new Promise((resolve, reject) => {
				const copyTheme = this.whiteLabels.find(({ theme: t }) => t === theme)
				const originalTheme = this.whiteLabelsCopy.find(({ theme: t }) => t === theme)
				const properties = ['logo', 'primary_color', 'secondary_color']
				const data = {}
				properties.forEach(property => {
					if (copyTheme[property] !== originalTheme[property]) {
						data[property] = copyTheme[property]
					}
				})
				if (Object.keys(data).length === 0) {
					reject('Nothing to update')
				}
				const promise = this.service.updateWhiteLabel(this.accountingFirm.id, theme, data)
				resolve(promise)
			}).then(() => {})
		}
	}
}
</script>
