import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments } from '@/helpers/methods'

export default {
  sa: {
    accountingFirmProduct: {
      read: async (accountingFirmId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) => {
        RequiredArguments({ accountingFirmId });
        return Backend.GET(
          URLS.api.sa.accountingFirms.products.uri,
          [accountingFirmId],
          params,
          doCancelPreviousRequest,
          doCancelAllOtherRequests
        );
      },
      replace: async (accountingFirmId, params = null, data, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) => {
        RequiredArguments({ accountingFirmId, data });
        return Backend.PUT(
          URLS.api.sa.accountingFirms.products.uri,
          [accountingFirmId],
          params,
          data,
          doCancelPreviousRequest,
          doCancelAllOtherRequests
        );
      },
    }
  }
}
